import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { BaseTextFieldProps } from '@mui/material/TextField';
import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import IntlTelInput, { IntlTelInputProps } from 'react-intl-tel-input';

import { insertAfter } from './utils';
import { NotchedOutline } from './NotchedOutline';

const formControlClassName = clsx('MuiTextField-root', 'MuiIntlTelField-root');
const containerClassName = clsx(
  'intl-tel-input',
  `MuiOutlinedInput-root`,
  `MuiOutlinedIntlTelInput-root`,
  'MuiInputBase-root',
  `MuiInputBase-colorPrimary`,
  'Mui-focused',
  'MuiInputBase-formControl'
);
const inputClassName = clsx(
  'form-control',
  `MuiOutlinedInput-input`,
  `MuiOutlinedIntlTelInput-input`,
  'MuiInputBase-input'
);

export const IntlTelField = React.forwardRef<
  IntlTelInput,
  IntlTelInputProps & BaseTextFieldProps
>((props) => {
  const {
    error,
    InputLabelProps,
    label,
    required,
    disabled,
    helperText,
    sx = {},
    onPhoneNumberChange = () => null,
    onPhoneNumberBlur = () => null,
    onPhoneNumberFocus = () => null,
    ...intlTelInputProps
  } = props;
  const telInputRef = useRef<HTMLInputElement | null | undefined>(undefined);
  const intlTelInputRef = useRef<IntlTelInput | null>(null);

  const fieldsetClassName = clsx('MuiOutlinedIntlTelInput-notchedOutline', {
    'MuiOutlinedIntlTelInput-notchedOutline-error': error
  });
  const fieldsetRef = useRef<HTMLFieldSetElement | null>(null);
  const setIntlTelInputRef = (instance: IntlTelInput | null) => {
    intlTelInputRef.current = instance;
  };

  useEffect(() => {
    telInputRef.current = intlTelInputRef.current?.tel;
    insertAfter(telInputRef.current, fieldsetRef.current);
  }, []);

  return (
    <FormControl
      className={formControlClassName}
      disabled={disabled}
      error={error}
      focused
      required={required}
      sx={{
        ...sx,
        '& input': { width: '250px' },
        '& .country-list li': { display: 'flex' }
      }}
      size="small"
    >
      {label && (
        <InputLabel
          sx={{
            color: error ? '#f44336 !important' : '#757575 !important'
          }}
          {...InputLabelProps}
          focused
          shrink
        >
          {label}
        </InputLabel>
      )}
      <IntlTelInput
        {...(intlTelInputProps as IntlTelInputProps)}
        onPhoneNumberFocus={onPhoneNumberFocus}
        onPhoneNumberBlur={onPhoneNumberBlur}
        onPhoneNumberChange={onPhoneNumberChange}
        ref={setIntlTelInputRef}
        containerClassName={containerClassName}
        inputClassName={inputClassName}
        format
      />
      <NotchedOutline
        aria-hidden
        className={fieldsetClassName}
        ref={fieldsetRef}
        label={label}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});
