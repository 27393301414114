export const insertAfter = <T extends Node>(
  referenceNode?: HTMLElement | null,
  newChild?: T | null
) => {
  if (newChild == null) {
    return;
  }

  referenceNode?.parentNode?.insertBefore(newChild, referenceNode?.nextSibling);
};
