import React, { FC } from 'react';
import OutlinedInput, {
  outlinedInputClasses
} from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/system';

const StylesOutlinedInput = styled(OutlinedInput)(`
  & .${outlinedInputClasses.notchedOutline} {
    border-color: black;
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: black;
  }
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: black;
  }
  &.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline} {
    border-color: #f44336;
  }
`);

interface InputProps {
  label: string;
  value: string;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  inputComponent?: any;
  sx?: { [index: string]: string | number };
  error?: boolean;
  helperText?: string;
  required?: boolean;
  name?: string;
  type?: string;
}

const Input: FC<InputProps> = ({
  label,
  value,
  onChange,
  inputComponent = null,
  sx = {},
  error = false,
  helperText = '',
  required = false,
  name = '',
  type = 'text'
}) => {
  return (
    <FormControl sx={sx} size="small">
      <InputLabel
        htmlFor="component-outlined"
        sx={{ color: error ? '#f44336 !important' : '#757575 !important' }}
      >
        {label}
      </InputLabel>
      <StylesOutlinedInput
        error={error}
        size="small"
        value={value}
        onChange={onChange}
        label={label}
        type={type}
        inputComponent={inputComponent as any}
        sx={{
          width: '300px'
        }}
        required={required}
        name={name}
      />
      <FormHelperText sx={{ color: '#f44336 !important' }}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default Input;
